/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import {
    object, func, string, shape, bool,
} from 'prop-types';

import { actions as tagManagerActions } from '../../../../../../state/ducks/TagManager';
import { getFeatureFlags, getPassportPageUrl } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { useAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const SignInDropdown = ({
    classes, trackEvent, passportPageUrl, featureFlags,
}) => {
    const { loginByRedirect } = useAuth();

    const isFacebookButtonEnabled = featureFlags['is-facebook-sign-in-enabled'];
    const isGoogleButtonEnabled = featureFlags['is-google-sign-in-enabled'];
    const isPassportButtonEnabled =  featureFlags['is-passport-enabled'];
    const isSignInDropdownHidingAccountOverview = featureFlags['is-signin-dropdown-hiding-account-overview'];

    return (
        <>
            <Button
                onKeyDown={() => { }}
                onClick={async () => {
                    trackEvent({
                        eventCategory: 'Sign In', eventAction: 'Login', eventLabel: 'Logon_From_Normal|<<pageType>>',
                    });

                    await loginByRedirect();
                }}
                className={classes.signInButton}
                data-test="signin-in-dropdown"
                id="hp-signin-dropdown"
                name="signinTextInDropdown"
            >
                Sign In
            </Button>
            <div className={classes.createAccountContainer}>
                <p>Don&apos;t have an account?</p>
                <Link
                    to="/"
                    onClick={async () => {
                        trackEvent({
                            eventCategory: 'Sign In', eventAction: 'Sign Up', eventLabel: 'Logon_From_Normal|<<pageType>>',
                        });

                        await loginByRedirect({}, { register: 'Y' });
                    }}
                    className={classes.clickLink}
                    data-test="click-here-in-dropdown"
                    id="click-here-dropdown"
                    aria-label="click-here"
                    name="clickHereInDropdown"
                >
                    Click Here
                </Link>
            </div>
            <div className={classes.socialSignIn}>
                { isFacebookButtonEnabled && (
                    <Button
                        className={classes.facebookButton}
                        onClick={async () => {
                            trackEvent({
                                eventCategory: 'Sign In', eventAction: 'Login', eventLabel: 'Logon_From_Facebook|<<pageType>>',
                            });

                            await loginByRedirect({ connection: 'facebook' });
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                            <path d="M17.007 18a.993.993 0 0 0 .993-.993V.993A.993.993 0 0 0 17.007 0H.994A.993.993 0 0 0 0 .993v16.014c0 .548.445.993.994.993h16.013" fill="#fff" />
                            <path d="M12.42 18v-6.97h2.34l.35-2.717h-2.69V6.578c0-.786.218-1.322 1.346-1.322h1.439v-2.43a19.24 19.24 0 0 0-2.097-.107c-2.074 0-3.494 1.266-3.494 3.59v2.004H7.27v2.716h2.345V18h2.806" fill="#3d539e" />
                        </svg>
                        <span>Login with Facebook</span>
                    </Button>
                )}
                { isGoogleButtonEnabled && (
                    <Button
                        className={classes.googleButton}
                        onClick={async () => {
                            trackEvent({
                                eventCategory: 'Sign In', eventAction: 'Login', eventLabel: 'Logon_From_Google|<<pageType>>',
                            });

                            await loginByRedirect({ connection: 'google-oauth2' });
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 152" width="20" height="20">
                            <path fill="#4285F4" d="M148.98 77.73c0-5.45-.47-10.71-1.36-15.73H76v29.55h41.11c-1.76 9.56-7.09 17.69-15.18 23.15v18.96h24.43c14.36-13.24 22.62-32.8 22.62-55.93z" />
                            <path fill="#34A853" d="M76 152c20.51 0 37.78-6.74 50.37-18.34l-24.43-18.96C95.1 119.3 86.3 122 76 122c-19.85 0-36.69-13.37-42.74-31.4H8.1v19.53C20.6 134.96 46.3 152 76 152z" />
                            <path fill="#FBBC05" d="M33.26 90.6c-1.54-4.61-2.4-9.52-2.4-14.6s.85-9.99 2.4-14.6V41.87H8.1C2.93 52.14 0 63.72 0 76s2.93 23.86 8.1 34.13L33.26 90.6z" />
                            <path fill="#EA4335" d="M76 30c11.22 0 21.28 3.87 29.21 11.42l.01.02 21.63-21.63C113.68 7.53 96.5 0 76 0 46.3 0 20.6 17.04 8.1 41.87L33.26 61.4C39.31 43.37 56.15 30 76 30z" />
                            <path fill="none" d="M0 0h152v152H0z" />
                        </svg>
                        <span>Sign in with Google</span>
                    </Button>
                )}
            </div>
            <div className={classes.actionsWrapper}>
                {!isSignInDropdownHidingAccountOverview && <Link className={classes.action} to="/account">Your Account</Link>}
                <a className={classes.action} href="/account/orders">Track Your Orders</a>
                {isPassportButtonEnabled
                    && (<Link className={classes.action} to={passportPageUrl}>Join Celebrations Passport</Link>)}
            </div>
            <div className={classes.benefits}>
                <strong>Benefits of Registration</strong>
                <ul className={classes.ul}>
                    <li>Fast, secure checkout</li>
                    <li>Exclusive promotions & offers</li>
                </ul>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    passportPageUrl: getPassportPageUrl(state),
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

SignInDropdown.propTypes = {
    classes: object.isRequired,
    trackEvent: func.isRequired,
    passportPageUrl: string.isRequired,
    featureFlags: shape({
        'is-facebook-sign-in-enabled': bool,
        'is-google-sign-in-enabled': bool,
        'is-passport-enabled': bool,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInDropdown);
